//-----------------------
// Image Component
//-----------------------

// PACKAGES
import React from "react"
import Image from "gatsby-image"

// COMPONENT BUILD
const SharpImage = ({ source, altText, filter, ...props }) => {
     // JSX TEMPLATE
    return (
        <>
            <Image
                loading="eager"
                fluid={source}
                placeholderStyle={filter}
                alt={altText}
                {...props}
            />
        </>
    )
}

export default SharpImage